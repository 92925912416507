.sideBar {
    height: 100vh;
    width: 220px;
    padding: 10px;
}

.activeItem .navLinkItemDiv {
    background-color: #DBE3FE;

    border-radius: 10px;
    color: #4D67EB;
}

.navLinkItem, .activeItem {
    text-decoration: none;
}