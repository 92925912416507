* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {

  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  width: 100%;
  height: 100%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

#root {
  width: 100%;
  height: 100%;
}

.MuiMenuItem-root {
  display: block !important;
}